<template>
    <div>
        <div style="margin:0px; padding:0px; overflow:hidden; height:100vh; width:100vw;">
            <iframe :src="iframeSrc" frameborder="0" style="overflow:hidden; height:100%; width:100%;" height="100%" width="100%"></iframe>
        </div>
    </div>
</template>
<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import store from "@/store/index"

const iframeSrc = computed(() => {

    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
    const appVersion = process.env.VUE_APP_URL_APP_VERSION
    return `https://apps.camptecnologia.com.br/oferta/${distribuidor}/${appVersion}/index.html`;
});

</script>